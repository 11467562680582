<template>
  <v-dialog width="800" v-model="dialogActive">
    <template v-slot:activator="{ on: dialogOn }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltipOn }">
          <v-btn class="no-print" icon v-on="{ ...dialogOn, ...tooltipOn }">
            <v-icon>fal fa-question-circle</v-icon>
          </v-btn>
        </template>
        <span>Help with Form Builder</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Form Builder Help</v-card-title>
      <v-tabs v-model="tab">
        <v-tab>General Structure</v-tab>
        <v-tab>Form Items</v-tab>
        <v-tab>Revisions</v-tab>
        <v-tab>Settings</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <p>A form will consist of one or more sections, with each section having the inputs for the user to fill out. There is also an optional "Info Card" that can be displayed above these sections. The layout displayed on this form is similar to how your form will be displayed.</p>
            <p>There are three types of sections that can be added: Card, Horizontal Stepper, and Vertical Stepper.</p>
            <p><b>Cards</b> are best used for shorter forms with only one or two sections. Each card is displayed at all times with a small space between them. This allows for some visual separation of the content, but can be a long scroll if the form is long.</p>
            <p><b>Horizontal Steppers</b> are good for forms with two to five sections. The section titles will be hidden on mobile devices to make sure it can fit, but section titles should be kept short if you have a lot of sections. Each section will be validated before moving onto the next section, but a user can move backwards between them. Note that in the form editor you will be able to move between the sections at will, but when the form is presented to users they will only be able to move forward or backwards using "Back" and "Next" buttons so that each section will get validated before proceeding further (not validated on Back, only on Next).</p>
            <p><b>Vertical Steppers</b> are good for any length of form with more than one section, with no upper limit. Since the steps are stacked, there is more space for the title of each section and for as many sections as needed. Each section will be validated before moving onto the next section, but a user can move backwards between them. Note that in the form editor you will be able to move between the sections at will, but when the form is presented to users they will only be able to move forward or backwards using "Back" and "Next" buttons so that each section will get validated before proceeding further (not validated on Back, only on Next).</p>
            <p>You can also configure what happens when someone submits the form (send an email, upload a PDF of the submission to a Google Drive folder, etc), and who can access the form submissions. This is done through the Settings tab (which has not yet been developed).</p>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <p>There are a number of different input types available on a form. Below are the different input types and their details.</p>
            <ul>
              <li><b>Text Field</b>: Standard single-line text input, allowing any typable character to be entered</li>
              <li><b>Email Address</b>: A single-line text input that requires a proper email address to be entered</li>
              <li><b>Number</b>: Numeric input. Only allows numbers to be entered</li>
              <li><b>Multi-line Text Field</b>: Longer text field that allows for multiple lines of text</li>
              <li><b>Select Box</b>: A dropdown featuring a short list of selectable items</li>
              <li><b>Auto-complete</b>: A searchable dropdown that filters the list of options as you type</li>
              <li><b>Combo Box</b>: An auto-complete that also allows a user to add their own option</li>
              <li><b>Radio Group</b>: A group of radio buttons, where only one can be selected; should only have a few options</li>
              <li><b>Checkbox</b>: Simple checkbox with a label</li>
              <li><b>Switch</b>: A toggle switch to choose between one of two options (similar to a checkbox but can be given different labels for on and off)</li>
              <li><b>Slider</b>: An input where a user can choose a numeric value on a slider between given minimum and maximum values</li>
              <li><b>Range Slider</b>: A slider input where a user can choose an upper and lower value between given minimum and maximum values</li>
              <li><b>Date Picker</b>: A popup calendar to select a date</li>
              <li><b>Time Picker</b>: A popup clock to select a time</li>
              <li><b>File Upload</b>: A file upload widget where you can specify what type(s) of file to upload</li>
              <li><b>HTML/Text Block</b>: A block of HTML or formatted text; not an input but just additional content or instructions to relay to the user</li>
              <li><b>Repeatable Block</b>: A block of fields that can be repeated multiple times (for instance multiple address blocks); includes option to have a button to add blocks or remove blocks.</li>
            </ul>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <p>Each form consists of one or more revisions. A revision is where the layout of the form is stored. When you create a new form, a revision is created with a version of 1. Each additional revision you make will increase the version number by 1. Each form submissions is connected to the revision of the form that was presented to them when they submitted it. This allows to track submissions separately when they were presented with different form layouts or input items (or different labels, all dependent on what was changed in the form).</p>
            <p>When you save a form, it will update the current revision, unless that revision has any submissions. In that case a new revision is created, preserving the information that was presented to the user from that revision. You can switch between revisions or manually create a new revision on the Form tab, using the Revision dropdown in the toolbar.</p>
            <p>On the Revisions tab you can see the different versions of the form and when they were last updated, along with how many submissions there are for each revision. You can choose which revision of the form should be active, so if there are changes that need to be made to the form at different times of the year you can create several revisions and then change which one is presented to users when they access the form. On this page you can also remove revisions that are no longer needed (as long as they have no submissions), or change which one is the active editing revision (which is the one that loads by default when you open the editor).</p>
            <p>You can also view a specific revision of the form from the Revisions tab by clicking the eye icon on the row with that revision. You can actually also send that link to someone to complete a specific revision of the form, if desired. Just note that the URL for alternate revisions are not "friendly" links as they have two long codes in the link unlike the nice short link generated with the short name on the Access tab.</p>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <p>There are a number of different settings that you can set on the Access and Confirmation tabs. These settings involve some settings related to loading the form (access), and for what to do when the form is submitted (confirmation).</p>
            <h5>Access Settings</h5>
            <ul>
              <li><b>Login Required</b>: Requires someone to be logged in to access the form</li>
              <li><b>Role Access</b>: Only those who have the selected role(s) can access the form; requires login access on the form</li>
              <li><b>Allow Anonymous</b>: Allows a user's submission to be anonymous (the form will not store who the logged-in user was who accessed the form); requires login access on the form</li>
              <li><b>Update Previous</b>: Loads the last submission of the form that the user made and pre-populates the form with that information; requires login access on the form</li>
              <li><b>Hide Navigation Menu</b>: Removes the side-bar navigation to have just the form on the page. This is best for forms that do not require a login</li>
              <li><b>Admin Access</b>: This section allows you to select users and/or roles and grant some level of admin access to the form (admin, edit the form, or review submissions); this is where you can specify for each user whether they should receive an email upon submission and how often (every submission, daily digest, or only when certain data in the submission matches a given value). Note: the submission notifications are not currently implemented but will be soon.</li>
            </ul>
            <h5>Confirmation Settings</h5>
            <ul>
              <li><b>Confirmation Message</b>: This is the text that will be displayed to the user as a confirmation of their form submission</li>
              <li><b>Send Email</b>: Sends an email to the person who submitted the form from a given email address when a form is submitted.</li>
            </ul>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn text @click="dialogActive = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref } from '@vue/composition-api'
export default {
  setup (props) {
    const tab = ref(0)
    const dialogActive = ref(false)

    return {
      tab,
      dialogActive
    }
  }
}
</script>
